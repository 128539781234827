import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../generic-page.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { ITOGCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { TOGCharacter } from '../../modules/tog/common/components/tog-character';

interface ITogCharacterNodes {
  nodes: ITOGCharacter[];
}

interface ITogCharacterEntry {
  allCharacters: ITogCharacterNodes;
}

interface IProps {
  data: ITogCharacterEntry;
}

const TOGCharactersPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          },
          {
            value: 'SSR+',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssrplus.png"
                height={24}
                alt="SSR+ rarity"
              />
            )
          },
          {
            value: 'UR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ur.png"
                height={24}
                alt="UR rarity"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_phys.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Magical',
            tooltip: 'Magical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_magic.png"
                width={24}
                alt="Magical"
              />
            )
          }
        ]
      },
      {
        key: 'color',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Yellow',
            tooltip: 'Yellow',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_yellow.png"
                width={24}
                alt="Yellow"
              />
            )
          },
          {
            value: 'Purple',
            tooltip: 'Purple',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_purple.png"
                width={24}
                alt="Purple"
              />
            )
          },
          {
            value: 'Red',
            tooltip: 'Red',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_red.png"
                width={24}
                alt="Red"
              />
            )
          },
          {
            value: 'Blue',
            tooltip: 'Blue',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_blue.png"
                width={24}
                alt="Blue"
              />
            )
          },
          {
            value: 'Green',
            tooltip: 'Green',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_green.png"
                width={24}
                alt="Green"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_assassin.png"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranged',
            tooltip: 'Ranged',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_ranged.png"
                width={24}
                alt="Ranged"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.color && activeFilters.color !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.color.toLowerCase() === activeFilters.color.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke characters-tog '}
      game="tog"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/tog/categories/category_characters.png"
            alt="Characters"
          />
        </div>
        <div className="page-details ">
          <h1>Tower of God: New World Characters</h1>
          <h2>List of available characters in Tower of God: New World.</h2>
          <p>
            Last updated: <strong>21/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar nikke">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="characters-simplified-view tog">
        {visibleCharacters.map((emp, index) => {
          return (
            <div className="single-character" key={index}>
              <TOGCharacter slug={emp.slug} mode="data" />
            </div>
          );
        })}
      </div>
      {/* <div className={`employees-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <TOGCharacter
                  slug={emp.slug}
                  mode="icon"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div> */}
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/tog/no_results.webp"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default TOGCharactersPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Tower of God: New World | Prydwen Institute"
    description="List of available characters in Tower of God: New World."
    game="tog"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulTogCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        color
        type
        class
        rarity
        notReleased
      }
    }
  }
`;
